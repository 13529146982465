// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-archive-abtin-sarabi-js": () => import("./../../../src/pages/archive/abtin-sarabi.js" /* webpackChunkName: "component---src-pages-archive-abtin-sarabi-js" */),
  "component---src-pages-archive-arash-nassiri-js": () => import("./../../../src/pages/archive/arash-nassiri.js" /* webpackChunkName: "component---src-pages-archive-arash-nassiri-js" */),
  "component---src-pages-archive-camila-beltran-js": () => import("./../../../src/pages/archive/camila-beltran.js" /* webpackChunkName: "component---src-pages-archive-camila-beltran-js" */),
  "component---src-pages-archive-index-js": () => import("./../../../src/pages/archive/index.js" /* webpackChunkName: "component---src-pages-archive-index-js" */),
  "component---src-pages-archive-kani-marouf-js": () => import("./../../../src/pages/archive/kani-marouf.js" /* webpackChunkName: "component---src-pages-archive-kani-marouf-js" */),
  "component---src-pages-archive-karrabing-js": () => import("./../../../src/pages/archive/karrabing.js" /* webpackChunkName: "component---src-pages-archive-karrabing-js" */),
  "component---src-pages-archive-nelson-makengo-js": () => import("./../../../src/pages/archive/nelson-makengo.js" /* webpackChunkName: "component---src-pages-archive-nelson-makengo-js" */),
  "component---src-pages-archive-pauline-curnier-jardin-js": () => import("./../../../src/pages/archive/pauline-curnier-jardin.js" /* webpackChunkName: "component---src-pages-archive-pauline-curnier-jardin-js" */),
  "component---src-pages-articles-arturo-escobar-and-mauricio-pardo-js": () => import("./../../../src/pages/articles/arturo-escobar-and-mauricio-pardo.js" /* webpackChunkName: "component---src-pages-articles-arturo-escobar-and-mauricio-pardo-js" */),
  "component---src-pages-articles-caitlin-vandertop-ghosts-of-the-plantation-js": () => import("./../../../src/pages/articles/caitlin-vandertop-ghosts-of-the-plantation.js" /* webpackChunkName: "component---src-pages-articles-caitlin-vandertop-ghosts-of-the-plantation-js" */),
  "component---src-pages-articles-elizabeth-povinelli-the-normavity-of-the-creeks-js": () => import("./../../../src/pages/articles/elizabeth-povinelli-the-normavity-of-the-creeks.js" /* webpackChunkName: "component---src-pages-articles-elizabeth-povinelli-the-normavity-of-the-creeks-js" */),
  "component---src-pages-articles-filip-de-boeck-kinshasa-and-its-immaterial-infrastructure-js": () => import("./../../../src/pages/articles/filip-de-boeck-kinshasa-and-its-immaterial-infrastructure.js" /* webpackChunkName: "component---src-pages-articles-filip-de-boeck-kinshasa-and-its-immaterial-infrastructure-js" */),
  "component---src-pages-articles-henderson-evidence-js": () => import("./../../../src/pages/articles/henderson-evidence.js" /* webpackChunkName: "component---src-pages-articles-henderson-evidence-js" */),
  "component---src-pages-articles-jafari-hein-transnational-building-of-a-modern-planning-regime-in-iran-js": () => import("./../../../src/pages/articles/jafari-hein-transnational-building-of-a-modern-planning-regime-in-iran.js" /* webpackChunkName: "component---src-pages-articles-jafari-hein-transnational-building-of-a-modern-planning-regime-in-iran-js" */),
  "component---src-pages-articles-rene-girard-the-sacrificial-crisis-js": () => import("./../../../src/pages/articles/rene-girard-the-sacrificial-crisis.js" /* webpackChunkName: "component---src-pages-articles-rene-girard-the-sacrificial-crisis-js" */),
  "component---src-pages-articles-villem-flusser-exile-and-creativity-js": () => import("./../../../src/pages/articles/villem-flusser-exile-and-creativity.js" /* webpackChunkName: "component---src-pages-articles-villem-flusser-exile-and-creativity-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-upcoming-js": () => import("./../../../src/pages/upcoming.js" /* webpackChunkName: "component---src-pages-upcoming-js" */)
}

