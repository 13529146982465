import { createMuiTheme } from '@material-ui/core/styles';

// A custom theme for this app
const theme = createMuiTheme({
  typography: {
    fontFamily: [
      'Acumin',
    ].join(','),
    subtitle1: {
      fontSize: 18,
    },
  },
  palette: {
    primary: {
      main: '#fff',
    },
    secondary : {
      main: '#00f54b',
    }
  }, 
});

export default theme;