import React, {useState} from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';
import theme from '../../src/assets/theme';
import '../../src/assets/fonts/FreightBigBook.css';
import '../../src/assets/fonts/Akzidenz.css';

import {
   IntroContext,
   PodcastProvider
 } from '../../src/context';


export default function TopLayout(props) {
  const [intro, setIntro] = useState(true)
  return (
    <React.Fragment>
      <Helmet>
        <meta name="viewport" content="minimum-scale=1, initial-scale=1, width=device-width" />
        <link
          href="https://fonts.googleapis.com/css?family=Roboto:400,500,700&display=swap"
          rel="stylesheet"
        />
        <title>DEMO | Moving Image Experimental Politics</title>
        <meta property="og:image" content="https://static.skllchain.com/abtin-sarabi.png" />

        <meta property="og:description" content="DEMO is a curatorial platform exploring the aesthetic and political potentialities of the moving image, regularly presenting experimental films concerning subaltern conditions and speculating on alternative modes of coexistence." />

        <meta property="og:url" content="https://demomovingimage.com" />

        <meta property="og:title" content="DEMO | Moving Image Experimental Politics" />
      </Helmet>
      <ThemeProvider theme={theme}>
        {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
        <CssBaseline />
        <IntroContext.Provider value={{ intro, setIntro }}>
        <PodcastProvider>
  
          {props.children}

        </PodcastProvider>
        </IntroContext.Provider>
      </ThemeProvider>
    </React.Fragment>
  );
}

TopLayout.propTypes = {
  children: PropTypes.node,
};