import React, { useState, useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import ReactPlayer from 'react-player/soundcloud'

const initial = {
  intro: true,
  setIntro: () => {}
}

export const IntroContext = React.createContext(initial);

//

const useStyles = makeStyles((theme) => ({
  player: {
    visibility: 'hidden',
    display: 'none'
  }
}));

export const PodcastContext = React.createContext();

export const usePodcast = () => {
  return useContext(PodcastContext)
}

export const PodcastProvider = ({ children }) => {
  const classes = useStyles()
  const [ podcast, SETPodcast ] = useState(false)
  const [ playing, SETPlaying ] = useState(false)

  const setPodcast = (e) => {
    SETPodcast(e)
  }

  const setPlaying = (e) => {
    SETPlaying(e)
  }

  const value = {
    podcast,
    setPodcast,
    playing,
    setPlaying
  }

  return (
    <PodcastContext.Provider
      value={value}
    >
    <ReactPlayer
      className={classes.player}
      playing={playing}
      url={podcast}
    />
      {children}
    </PodcastContext.Provider>
  );
};
